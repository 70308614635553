import { blogAppDefId } from './apps';
import { SECTIONS } from './sections';
import translation from '../services/translation';

const { translate } = translation;

const formatDescription = ({ text = '', bulletPoints = [] } = {}) =>
  `${text}<ul>${bulletPoints.map((bulletPoint) => `<li>${bulletPoint}</li>`).join('')}</ul>`;

export const createMemberPages = (assetsUrl) => {
  const getImageUrl = (fileName) => `${assetsUrl}/member-pages/${fileName}`;
  const iconURL = getImageUrl('blog-icon.png');
  const origin = translate('member-pages.origin');

  const memberPages = {
    [SECTIONS.ABOUT]: {
      appDefinitionId: '14dbef06-cc42-5583-32a7-3abd44da4908',
      pageId: 'about',
      method: 'addApplication',
      loginMenuTitle: 'Profile',
      showInLoginMenu: true,
      menuOrder: 1,
      socialHome: true,
      urlOverride: 'profile',
      page: {
        name: translate('member-pages.about.name'),
        shortDescription: translate('member-pages.about.short-description'),
        description: formatDescription({
          text: translate('member-pages.about.description.text'),
          bulletPoints: [
            translate('member-pages.about.description.bullet-point-1'),
            translate('member-pages.about.description.bullet-point-2'),
            translate('member-pages.about.description.bullet-point-3'),
            translate('member-pages.about.description.bullet-point-4'),
            translate('member-pages.about.description.bullet-point-5'),
          ]
        }),
        iconURL: getImageUrl('members-area-icon.svg'),
        origin: translate('member-pages.about.origin'),
        screenshots: [getImageUrl('profile-screenshot.png'), getImageUrl('profile-screenshot-mobile.png')],
      },
    },
    [SECTIONS.FOLLOWERS]: {
      appDefinitionId: '14ebe801-d78a-daa9-c9e5-0286a891e46f',
      pageId: 'following_followers',
      method: 'addApplication',
      showInMemberMenu: false,
      urlOverride: 'followers',
      menuOrder: 2,
      page: {
        name: translate('member-pages.followers.name'),
        shortDescription: translate('member-pages.followers.short-description'),
        description: formatDescription({
          text: translate('member-pages.followers.description.text'),
          bulletPoints: [
            translate('member-pages.followers.description.bullet-point-1'),
            translate('member-pages.followers.description.bullet-point-2'),
            translate('member-pages.followers.description.bullet-point-3'),
            translate('member-pages.followers.description.bullet-point-4'),
          ],
        }),
        iconURL: getImageUrl('members-area-icon.svg'),
        origin: translate('member-pages.followers.origin'),
        screenshots: [getImageUrl('followers-screenshot.png'), getImageUrl('followers-screenshot-mobile.png')],
      },
    },
    [SECTIONS.NOTIFICATIONS]: {
      appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
      pageId: 'notifications_app',
      numbers: {
        key: 'notificationsCount',
        default: 0,
      },
      method: 'addApplication',
      menuOrder: 4,
      social: false,
      urlOverride: 'notifications',
    },
    [SECTIONS.SETTINGS]: {
      appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
      pageId: 'settings',
      method: 'addApplication',
      menuOrder: 4,
      social: false,
      urlOverride: 'settings',
    },
    [SECTIONS.POSTS]: {
      numbers: {
        key: 'postsCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-posts-page',
      urlOverride: 'blog-posts',
      visibleForRoles: ['admin', 'blog_writer', 'blog_editor'],
      page: {
        name: translate('member-pages.posts.name'),
        shortDescription: translate('member-pages.posts.short-description'),
        description: formatDescription({
          text: translate('member-pages.posts.description.text'),
          bulletPoints: [
            translate('member-pages.posts.description.bullet-point-1'),
            translate('member-pages.posts.description.bullet-point-2'),
            translate('member-pages.posts.description.bullet-point-3'),
            translate('member-pages.posts.description.bullet-point-4'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('posts-screenshot-desktop.png'), getImageUrl('posts-screenshot-mobile.png')],
      },
    },
    [SECTIONS.COMMENTS]: {
      numbers: {
        key: 'commentsCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-comments-page',
      urlOverride: 'blog-comments',
      menuOrder: 2,
      page: {
        name: translate('member-pages.comments.name'),
        shortDescription: translate('member-pages.comments.short-description'),
        description: formatDescription({
          text: translate('member-pages.comments.description.text'),
          bulletPoints: [
            translate('member-pages.comments.description.bullet-point-1'),
            translate('member-pages.comments.description.bullet-point-2'),
            translate('member-pages.comments.description.bullet-point-3'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('comments-screenshot-desktop.png'), getImageUrl('comments-screenshot-mobile.png')],
      },
    },
    [SECTIONS.LIKES]: {
      numbers: {
        key: 'likesCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-likes-page',
      urlOverride: 'blog-likes',
      menuOrder: 2,
      page: {
        name: translate('member-pages.likes.name'),
        shortDescription: translate('member-pages.likes.short-description'),
        description: formatDescription({
          text: translate('member-pages.likes.description.text'),
          bulletPoints: [
            translate('member-pages.likes.description.bullet-point-1'),
            translate('member-pages.likes.description.bullet-point-2'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('likes-screenshot-desktop.png'), getImageUrl('likes-screenshot-mobile.png')],
      },
    },
    [SECTIONS.DRAFTS]: {
      numbers: {
        key: 'draftsCount',
        default: 0,
      },
      method: 'addSection',
      appDefinitionId: blogAppDefId,
      pageId: 'member-drafts-page',
      social: false,
      showInLoginMenu: true,
      urlOverride: 'my-drafts',
      menuOrder: 2,
      visibleForRoles: ['admin', 'blog_writer', 'blog_editor'],
      page: {
        name: translate('member-pages.drafts.name'),
        shortDescription: translate('member-pages.drafts.short-description'),
        description: formatDescription({
          text: translate('member-pages.drafts.description.text'),
          bulletPoints: [
            translate('member-pages.drafts.description.bullet-point-1'),
            translate('member-pages.drafts.description.bullet-point-2'),
            translate('member-pages.drafts.description.bullet-point-3'),
          ],
        }),
        iconURL,
        origin,
        isNew: false,
        screenshots: [getImageUrl('drafts-screenshot-desktop.png'), getImageUrl('drafts-screenshot-mobile.png')],
      },
    },
  };

  return memberPages;
};
